import Action from './modules/Action';

// jQuery
(function ($) {
  $(function () {

    const $body = $('body');

    const $sliders = $('.js--slider');
    $sliders.each((index, element) => {
      const $slider = $(element);
      const sliderData = $slider.data('slider');

      $slider.slick(sliderData || {});
    });

    // Клик
    $body.on('click', '[data-click]', function () {
      let data = $(this).data('click');

      new Action(data, this);
      if ($(this).is('a')) return false;
    });

  });
})(jQuery);
